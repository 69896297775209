import React, { useState } from 'react';

import { gql, useQuery } from '@apollo/client';

import Hero from 'components/ui/Hero';
import List from 'components/ui/List';
import ScheduledPayment from 'components/profiles/Scheduled';

import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

export default function Scheduled({ itemID }) {
	const [ tab, setTab ] = useState('active');
	const [ view, setView ] = useState('large');
	const [ item, setItem ] = useState(itemID);
	const { loading, data, error } = useQuery(SUBSCRIPTIONS, {
		variables: { status: tab }
	});

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	if (item) return <ScheduledPayment itemID={item} />;

	return (
		<div>
			<Hero
				color="primary"
				icon="clock"
				title="Scheduled payments"
				subtitle="Recurring payments, subscriptions, payment plans"
				tabs={tabs}
				activeTab={tab}
				setTab={setTab}
				view={view}
				setView={setView}
			/>
			<List type="scheduled payments" view={view} data={data.subscriptions} actions={[ setItem ]} />
		</div>
	);
}

const tabs = [
	{ title: 'active', icon: 'calendar-check' },
	{ title: 'missed', icon: 'calendar-times' },
	{ title: 'paused', icon: 'pause' },
	{ title: 'canceled', icon: 'trash-alt' }
];

// *****************************************
// **************** GraphQL ****************
// *****************************************

const SUBSCRIPTIONS = gql`
	query Subscriptions($status: String) {
		subscriptions(status: $status) {
			id
			amount
			description
			status
			startDate
			currency
			frequency
			lastRun
			nextRun
			payments {
				id
				status
				date
				card {
					id
					brand
					last4
				}
			}
			consumer {
				id
				email
				firstName
				lastName
				avatar
			}
		}
	}
`;
