import React, { useState } from 'react';

import { gql, useQuery } from '@apollo/client';

import Hero from 'components/ui/Hero';
import List from 'components/ui/List';
import Transaction from 'components/profiles/Activity';

import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

export default function Activity({ itemID }) {
	const [ view, setView ] = useState('large');
	const [ tab, setTab ] = useState('approved');
	const [ item, setItem ] = useState(itemID);
	const { loading, data, error } = useQuery(PAYMENTS, {
		variables: { status: tab }
	});

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	if (item) return <Transaction id={item} />;

	return (
		<div>
			<Hero
				color="primary"
				icon="stream"
				title="Activity Feed"
				subtitle="Everything going on with your account"
				tabs={tabs}
				activeTab={tab}
				setTab={setTab}
				view={view}
				setView={setView}
			/>
			<List type="activity" view={view} data={data.payments} actions={[ setItem ]} />
		</div>
	);
}

const tabs = [
	{ title: 'approved', icon: 'check-circle' },
	{ title: 'declined', icon: 'times-circle' },
	{ title: 'refunded', icon: 'undo-alt' }
];

// *****************************************
// **************** GraphQL ****************
// *****************************************

const PAYMENTS = gql`
	query Payments($status: String) {
		payments(status: $status) {
			id
			date
			amount
			status
			isLive
			authCode
			consumer {
				id
				firstName
				lastName
				email
				avatar
			}
			card {
				id
				brand
				last4
			}
		}
	}
`;
