import React from 'react';
import InputMask from 'react-input-mask';
export function Date(props) {
	const { field, form: { touched, errors }, placeholder, size, label, icon, loading } = props;

	return (
		<div className="field">
			<label className={`label is-${size}`}>{label}</label>
			<div
				className={`control is-${size} ${icon && 'has-icons-left'} has-icons-right ${loading && 'is-loading'}`}
			>
				<input
					type="date"
					placeholder={placeholder}
					className={`input is-${size} is-${touched[field.name] && errors[field.name] && 'danger'}`}
					{...field}
				/>

				{icon && (
					<span className={`icon is-${size} is-left`}>
						<i className={`fas fa-${icon}`} />
					</span>
				)}

				{touched[field.name] &&
				!errors[field.name] && (
					<span className={`icon is-${size} is-right`}>
						<i className={`fas fa-check`} />
					</span>
				)}

				{touched[field.name] &&
				errors[field.name] && (
					<span className={`icon is-${size} is-right`}>
						<i className={`fas fa-times`} />
					</span>
				)}
			</div>

			{touched[field.name] && errors[field.name] && <p className="help is-danger">{errors[field.name]}</p>}
		</div>
	);
}
export function Input({ field, form: { touched, errors }, type, placeholder, size, label, icon, loading, mask }) {
	return (
		<div className="field">
			<label className={`label is-${size}`}>{label}</label>
			<div
				className={`control is-${size} ${icon && 'has-icons-left'} has-icons-right ${loading && 'is-loading'}`}
			>
				{!mask ? (
					<input
						type={type}
						placeholder={placeholder}
						className={`input is-${size} is-${touched[field.name] && errors[field.name] && 'danger'}`}
						{...field}
					/>
				) : (
					<InputMask
						placeholder={placeholder}
						className={`input is-${size} is-${touched[field.name] && errors[field.name] && 'danger'}`}
						mask={mask}
						maskChar=" "
						{...field}
					/>
				)}
				{icon && (
					<span className={`icon is-${size} is-left`}>
						<i className={`fas fa-${icon}`} />
					</span>
				)}

				{touched[field.name] &&
				!errors[field.name] && (
					<span className={`icon is-${size} is-right`}>
						<i className={`fas fa-check`} />
					</span>
				)}

				{touched[field.name] &&
				errors[field.name] && (
					<span className={`icon is-${size} is-right`}>
						<i className={`fas fa-times`} />
					</span>
				)}
			</div>

			{touched[field.name] && errors[field.name] && <p className="help is-danger">{errors[field.name]}</p>}
		</div>
	);
}
export function Select(props) {
	const { field, form: { touched, errors }, data, placeholder, size, label, icon, loading } = props;

	return (
		<div className="field">
			<label className={`label is-${size}`}>{label}</label>
			<div
				className={`control is-${size} ${icon && 'has-icons-left'} has-icons-right ${loading && 'is-loading'}`}
			>
				<div className={`select is-${size} is-${touched[field.name] && errors[field.name] && 'danger'}`}>
					<select {...field}>
						<option>{placeholder}</option>
						{data &&
							data.length > 0 &&
							data.map((item) => (
								<option key={item.value} value={item.value}>
									{item.label}
								</option>
							))}
					</select>
				</div>

				{icon && (
					<span className={`icon is-${size} is-left`}>
						<i className={`fas fa-${icon}`} />
					</span>
				)}

				{touched[field.name] &&
				!errors[field.name] && (
					<span className={`icon is-${size} is-right`}>
						<i className={`fas fa-check`} />
					</span>
				)}

				{touched[field.name] &&
				errors[field.name] && (
					<span className={`icon is-${size} is-right`}>
						<i className={`fas fa-times`} />
					</span>
				)}
			</div>

			{touched[field.name] && errors[field.name] && <p className="help is-danger">{errors[field.name]}</p>}
		</div>
	);
}
