import React from "react";
import { Link } from "@reach/router";

import { Delete, Level, Left, Right } from "./bulma";

// import logo from "assets/images/logo.png";

function Menu({ title, children }) {
  return (
    <aside className="menu is-dark">
      {title && <p className="menu-label">{title}</p>}
      <ul className="menu-list">{children}</ul>
    </aside>
  );
}

function MenuItem({ extended, name, icon, route, close }) {
  if (extended) {
    return (
      <li>
        <Link
          to={route}
          onClick={close}
          getProps={({ isCurrent }) => {
            return isCurrent ? { className: "is-active" } : null;
          }}
        >
          <span className="icon">
            <i className={`fas fa-${icon}`} />
          </span>{" "}
          <strong>{name}</strong>
        </Link>
      </li>
    );
  } else
    return (
      <li className="has-text-centered">
        <Link
          to={route}
          getProps={({ isCurrent }) => {
            return isCurrent ? { className: "has-text-primary" } : null;
          }}
        >
          <span className="icon is-large">
            <i className={`fas fa-${icon} fa-lg`} />
          </span>
          <p className="is-size-7 has-text-weight-semibold">{name}</p>
        </Link>
      </li>
    );
}

export default function SidePanel({ extendedMenu, extendMenu }) {
  return (
    <aside className="menu is-dark sticky">
      <div>
        <div className="is-hidden-mobile">
          <ul className="menu-list">
            <MenuItem name="Dashboard" icon="chart-pie" route="/" />
            <MenuItem name="Activity" icon="stream" route="/activity" />
            <MenuItem name="Customers" icon="user-friends" route="/customers" />
            <MenuItem name="Invoices" icon="paperclip" route="/invoices" />
            <MenuItem name="Scheduled" icon="clock" route="/scheduled" />
          </ul>
        </div>
        <ExtendedMenu extendedMenu={extendedMenu} extendMenu={extendMenu} />
      </div>
    </aside>
  );
}

function ExtendedMenu({ extendedMenu, extendMenu }) {
  return (
    <div
      className={`navigation-view ${extendedMenu ? "is-active" : ""}`}
      id="myNavigationView"
    >
      <Level mobile>
        <Left></Left>
        <Right>
          <div style={{ padding: 15 }}>
            <Delete size="large" action={() => extendMenu(!extendedMenu)} />
          </div>
        </Right>
      </Level>

      <Menu>
        <MenuItem
          extended
          name="Dashboard"
          icon="chart-pie"
          route="/"
          close={() => extendMenu(!extendedMenu)}
        />
        <MenuItem
          extended
          close={() => extendMenu(!extendedMenu)}
          name="Account Activity"
          icon="credit-card"
          route="/activity"
        />
        <MenuItem
          extended
          name="Customer Profiles"
          icon="user-friends"
          route="/customers"
          close={() => extendMenu(!extendedMenu)}
        />
        <MenuItem
          extended
          name="Smart Invoices"
          icon="paperclip"
          route="/invoices"
          close={() => extendMenu(!extendedMenu)}
        />
        <MenuItem
          extended
          name="Scheduled Payments"
          icon="clock"
          route="/scheduled"
          close={() => extendMenu(!extendedMenu)}
        />
      </Menu>
    </div>
  );
}
