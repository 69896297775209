import React from "react";

// import avatar from "assets/images/avatar_sq.png";
export default function Spinner() {
  return (
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-one-third has-text-centered">
              {/* <img alt="Unmerchant" src={avatar} width="150" /> */}
              <br />
              <p className="title is-4">Please wait...</p>
              <progress className="progress is-dark" max="100" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
