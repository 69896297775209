const firebaseConfig = {
	apiKey: 'AIzaSyD78uh7WmMfaD0NSB2k_lzrL9xhOCR1UqY',
	authDomain: 'paymentninja1.firebaseapp.com',
	databaseURL: 'https://paymentninja1.firebaseio.com',
	projectId: 'paymentninja1',
	storageBucket: 'paymentninja1.appspot.com',
	messagingSenderId: '721308785169',
	appId: '1:721308785169:web:e16aa8022e154480'
};

export default firebaseConfig;
