import 'react-table/react-table.css';
import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import { Formik, Form, FastField } from 'formik';
import { object, string, number, date } from 'yup';
import ReactTable from 'react-table';

import { Box, Button } from 'components/ui/bulma/elements';
import { Date as DateInput, Input } from 'components/ui/bulma/form';

import CustomerLookup from 'components/ui/CustomerLookup';
import Modal from 'components/ui/Modal';
import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

// import customerName from 'utils/customerName';

export default function CreateInvoice({ onClose }) {
	const [ invoiceItems, setInvoiceItems ] = useState(initItems);
	const [ itemsError, setItemsError ] = useState(false);
	const [ createInvoice, { data, loading, error } ] = useMutation(CREATE_INVOICE, {
		refetchQueries: [ 'Account', 'Invoices' ]
	});

	const renderEditable = (cellInfo) => (
		<div
			style={{ backgroundColor: '#fafafa' }}
			contentEditable
			suppressContentEditableWarning
			onBlur={(e) => {
				const items = [ ...invoiceItems ];
				items[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
				setItemsError(false);
				setInvoiceItems(items);
			}}
			dangerouslySetInnerHTML={{
				__html: invoiceItems[cellInfo.index][cellInfo.column.id]
			}}
		/>
	);

	const total = () => {
		let total = 0;
		invoiceItems.map((item) => {
			if (item.price > 0 && item.qty > 0) total = total + item.price * item.qty;
			return total;
		});

		return Number(total).toFixed(2);
	};

	const tax = (taxRate) => {
		return Number(Number(total()) * taxRate / 100).toFixed(2);
	};

	const due = (taxRate) => {
		return Number(Number(total()) + Number(tax(taxRate))).toFixed(2);
	};

	if (error) return <Error />;
	if (loading)
		return (
			<div className="columns is-mobile is-centered">
				<div className="column is-half">
					<Box>
						<Spinner />
					</Box>
				</div>
			</div>
		);

	if (data && data.createInvoice && data.createInvoice.id)
		return (
			<div className="container">
				<div className="columns is-centered">
					<div className="column is-half has-text-centered">
						<Box>
							<span className="icon is-large has-text-success">
								<i className="fas fa-check-circle fa-3x" />
							</span>

							<br />
							<h1 className="title is-3">The invoice has been sent.</h1>
							<button onClick={onClose} className="button is-large is-fullwidth">
								Close
							</button>
						</Box>
					</div>
				</div>
			</div>
		);

	return (
		<Formik
			initialValues={{
				customer: '',
				dueDate: '',
				description: '',
				// items: '',
				taxRate: ''
			}}
			validationSchema={validationSchema}
			onSubmit={({ customer, description, dueDate, taxRate }) => {
				const items = invoiceItems.filter((row) => row.item !== '' && row.price !== '' && row.qty !== '');

				const invoiceProps = {
					consumer: customer.id,
					items,
					currency: 'USD',
					description: description,
					dueDate: dueDate,
					taxRate: taxRate.toString()
				};

				if (items.length > 0) {
					createInvoice({
						variables: {
							invoiceProps
						}
					});
				} else {
					setItemsError(true);
				}
			}}
		>
			{({ isSubmitting, values, errors }) => (
				<Modal icon="credit-card" title="Invoice" onClose={onClose}>
					<Form>
						<FastField
							name="customer"
							label="Select Customer"
							icon="user"
							component={CustomerLookup}
							placeholder="Type a name or email"
						/>

						<div className="field-body">
							<FastField
								name="dueDate"
								label="Date Due"
								icon="calendar-check"
								component={DateInput}
								placeholder="Frequency"
							/>
							<FastField
								name="description"
								label="Description"
								icon="info-circle"
								component={Input}
								placeholder="Example: Monthly service"
							/>
						</div>
						<br />
						<ReactTable
							data={invoiceItems}
							showPagination={false}
							columns={[
								{
									Header: 'Item',
									accessor: 'item',
									resizable: false,
									minWidth: 300,
									Cell: renderEditable
								},
								{
									Header: 'Price ($)',
									accessor: 'price',
									resizable: false,
									Cell: renderEditable
								},
								{
									Header: 'Qty',
									accessor: 'qty',
									resizable: false,
									Cell: renderEditable
								},
								{
									Header: 'Total',
									id: 'subtotal',
									resizable: false,
									accessor: (d) => (
										<strong>
											{Number(d.price) * Number(d.qty) > 0 &&
												`$${(Number(d.price) * Number(d.qty)).toFixed(2)}`}
										</strong>
									)
								}
							]}
							defaultPageSize={5}
							className="-striped -highlight"
						/>
						{itemsError && (
							<p className="help is-danger">At least one item is required for a valid invoice!</p>
						)}
						<br />
						<nav className="level">
							<div className="level-left">
								<div className="field-body">
									<FastField
										type="number"
										name="taxRate"
										label="Tax rate"
										icon="percent"
										component={Input}
										placeholder="Tax rate"
									/>
								</div>
								<br />
							</div>

							<div className="level-right">
								<div>
									<p className="title is-5">
										Total: <small>$</small>
										{total()}
									</p>
									<p className="subtitle">
										Tax({values.taxRate}%): <small>$</small>
										{tax(values.taxRate)}
									</p>
									<p className="title is-4">
										Due: <small>$</small>
										{due(values.taxRate)}
									</p>
								</div>
							</div>
						</nav>
						<hr />
						<Button type="submit" block icon="check-circle" disabled={isSubmitting}>
							Send Invoice
						</Button>
					</Form>
				</Modal>
			)}
		</Formik>
	);
}

const validationSchema = object().shape({
	customer: string().required('Please enter a customer!'),
	dueDate: date().required('Please choose a due date!'),
	description: string().min(2, 'Too Short!').max(20, 'Too Long!').required('Please enter a description!'),
	taxRate: number().positive().min(0).max(20).required('Tax rate is required!')
	// items: array()
	// 	.of(
	// 		object().shape({
	// 			item: string().required(),
	// 			price: number().required().positive(),
	// 			qty: number().required().positive().integer()
	// 		})
	// 	)
	// 	.compact()
	// 	.min(1)
	// 	.required('Please add some items!')
});

const initItems = [
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' },
	{ item: '', price: '', qty: '' }
];

const CREATE_INVOICE = gql`
	mutation CreateInvoice($invoiceProps: InvoiceProps) {
		createInvoice(invoiceProps: $invoiceProps) {
			id
			status
		}
	}
`;
