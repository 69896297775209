import React from 'react';
import { gql, useMutation } from '@apollo/client';

import { FastField, useFormikContext } from 'formik';
import { FormikWizard, useFormikWizard } from 'formik-wizard';
import { object, string } from 'yup';

import { isValid, sanitize } from 'tin-validator';
import { accountNumber, routingNumber } from 'us-bank-account-validator';

// TODO **** Package Size ****
// import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
// TODO ****

import ButtonSelector from 'components/ui/ButtonSelector';
import Modal from 'components/ui/Modal';
import Places from 'components/ui/Places';

import { Button, ButtonGroup } from 'components/ui/bulma/elements';

import { Input } from 'components/ui/bulma/form';

export default function Activate({ onClose }) {
	const [ activateMerchant, { data } ] = useMutation(ACTIVATE_MERCHANT, {
		refetchQueries: [ 'Account' ]
	});

	const handleSubmit = React.useCallback(
		(values) => {
			const {
				entity: { entity, taxID, type },
				bank: { account, bic },
				contact: { address, phone, email }
			} = values;

			const merchantProps = {
				entity,
				type,
				taxid: taxID,
				address,
				email,
				phone,
				account,
				bic
				// ownerFirstName: String
				// ownerLastName: String
				// ownerDOB: String
				// ownerAddress: String
			};

			activateMerchant({ variables: { merchantProps } });
		},
		[ activateMerchant ]
	);

	if (data && data.activateMerchant && data.activateMerchant.status === 'pending') onClose();

	return (
		<Modal icon="credit-card" title="Activate" onClose={onClose}>
			<FormikWizard steps={steps} onSubmit={handleSubmit} render={FormWrapper} />
		</Modal>
	);
}

const WizardProgress = ({ steps, currentStep }) => (
	<nav className="breadcrumb is-medium has-succeeds-separator" aria-label="breadcrumbs">
		{/* <Progress value={(steps.indexOf(currentStep) + 1) / steps.length * 100} color="primary" /> */}
		<ul>
			{steps.map((step) => (
				<li
					key={step}
					className={
						stepProps[steps.indexOf(step)].id === stepProps[steps.indexOf(currentStep)].id ? (
							'is-active'
						) : (
							''
						)
					}
				>
					<a href="#">
						<span className="icon is-small">
							<i className={`fa fa-${stepProps[steps.indexOf(step)].icon}`} aria-hidden="true" />
						</span>
						<span>{`${stepProps[steps.indexOf(step)].name}`}</span>
					</a>
				</li>
			))}
		</ul>
		<hr />

		{/* <h1 className="title">{`${stepProps[steps.indexOf(currentStep)].title}`}</h1>
		<h2 className="subtitle">{stepProps[steps.indexOf(currentStep)].subtitle}</h2> */}
	</nav>
);

const FormWrapper = ({
	currentStep,
	steps,
	children,
	isLastStep,
	status,
	goToPreviousStep,
	canGoBack,
	actionLabel
}) => (
	<div>
		<WizardProgress steps={steps} currentStep={currentStep} />

		{children}
		{status && (
			<div>
				{status.message}
				<hr />
			</div>
		)}

		<hr />
		<ButtonGroup centered>
			<Button size="medium" icon="angle-left" color="white" action={goToPreviousStep} disabled={!canGoBack}>
				Back
			</Button>
			<Button type="submit" block size="medium" icon="check-circle">
				{actionLabel || (isLastStep ? 'Finish' : 'Continue')}
			</Button>
		</ButtonGroup>
	</div>
);

function Entity() {
	const { values, setFieldValue, touched, errors } = useFormikContext();

	return (
		<div>
			<ButtonSelector
				name="type"
				label="Business entity type"
				size="medium"
				buttons={entityTypes}
				active={values.type}
				setActive={(type) => setFieldValue('type', type)}
				error={touched.type && errors.type}
			/>
			<br />
			<FastField
				name="entity"
				label={values.type === 'individual' ? 'Full name' : 'Entity name'}
				icon={values.type === 'individual' ? 'user-circle' : 'award'}
				size="large"
				component={Input}
				placeholder={values.type === 'individual' ? 'Full name' : 'Entity name'}
			/>
			<br />

			<FastField
				name="taxID"
				label={values.type === 'individual' ? 'SSN' : 'EIN / TIN'}
				icon={values.type === 'individual' ? 'shield-alt' : 'hashtag'}
				size="large"
				component={Input}
				mask={values.type === 'individual' ? '999-99-9999' : '99-9999999'}
				placeholder={values.type === 'individual' ? 'SSN' : 'EIN / TIN'}
			/>
		</div>
	);
}

function Bank() {
	return (
		<div>
			<FastField
				name="account"
				label="Account number"
				icon="money-check-alt"
				size="large"
				component={Input}
				mask="999999999999"
				placeholder="Account number"
			/>
			<br />
			<br />
			<FastField
				name="bic"
				label="ABA / Routing number"
				icon="university"
				size="large"
				component={Input}
				mask="999999999"
				placeholder="ABA / Routing number"
			/>
		</div>
	);
}
function Contact() {
	const { setFieldValue } = useFormikContext();
	return (
		<div>
			<FastField
				name="address"
				label="Business address"
				icon="map-marker-alt"
				size="large"
				component={Places}
				placeholder="Start typing an address or a business name"
				action={({ formatted_address }) => setFieldValue('address', formatted_address)}
			/>

			<FastField
				name="phone"
				label="Phone"
				icon="phone"
				size="large"
				component={Input}
				mask="(999) 999 9999"
				placeholder="Phone number"
			/>
			<br />
			<FastField
				name="email"
				label="Email for important info"
				icon="at"
				size="large"
				component={Input}
				placeholder="Email address"
			/>
		</div>
	);
}

function Summary() {
	const { values } = useFormikWizard();
	const { entity: { entity, taxID, type }, bank: { account, bic }, contact: { address, phone, email } } = values;

	return (
		<div>
			<p className="title">Please verify your information:</p>
			<br />
			<p className="subtitle">
				<strong>Business entity:</strong> {entity}, {type}
			</p>
			<p className="subtitle">
				<strong>TIN:</strong> {taxID}
			</p>

			<hr />
			<p className="subtitle">
				<strong>Checking account:</strong> {account}
			</p>
			<p className="subtitle">
				<strong>ABA / Routing:</strong> {bic}
			</p>

			<hr />
			<p className="subtitle">
				<strong>Address:</strong> {address}
			</p>
			<p className="subtitle">
				<strong>Phone:</strong> {phone}
			</p>
			<p className="subtitle">
				<strong>Email:</strong> {email}
			</p>
		</div>
	);
}

const steps = [
	{
		id: 'entity',
		component: Entity,
		initialValues: {
			entity: '',
			type: '',
			taxID: ''
		},
		validationSchema: object().shape({
			type: string().required('Please select your entity type!'),
			entity: string().required('Please enter your entity name!'),
			taxID: string()
				.test('taxID', 'Please enter a valid tax identification number!', (value) => isValid(sanitize(value)))
				.required('Please enter a valid tax identification number!')
		})
		// actionLabel: 'Next: Choose location'
	},
	{
		id: 'bank',
		component: Bank,
		initialValues: {
			account: '',
			bic: ''
		},
		validationSchema: object().shape({
			account: string()
				.test('account', 'Please enter a valid account number number!', (value) => accountNumber(value).isValid)
				.required('Please enter a valid account number number!'),
			bic: string()
				.test('bic', 'Please enter a valid routing number!', (value) => routingNumber(value).isValid)
				.required('Please enter a valid routing number!')
		})
	},
	{
		id: 'contact',
		component: Contact,
		initialValues: {
			address: '',
			phone: '',
			email: ''
		},
		validationSchema: object().shape({
			address: string().required('Please enter your business address!'),
			phone: string().required('Please enter a valid phone number!'),
			email: string().email('Please enter a valid email!').required('Please enter an email!')
		})
	},

	{
		id: 'summary',
		component: Summary
	}
];

const stepProps = [
	{
		id: 'entity',
		icon: 'award',
		name: 'Business Entity',
		title: '',
		subtitle: ''
	},
	{
		id: 'bank',
		icon: 'dollar-sign',
		name: 'Bank Account',
		title: '',
		subtitle: ''
	},

	{
		id: 'contact',
		icon: 'envelope',
		name: 'Contact Info',
		title: '',
		subtitle: ''
	},
	{
		id: 'summary',
		icon: 'tasks',
		name: 'Summary',
		title: '',
		subtitle: ''
	}
];

const entityTypes = [
	{ name: 'Individual', value: 'Individual' },
	{ name: 'LLC', value: 'LLC' },
	{ name: 'Corporation', value: 'Corp.' },
	{ name: 'Partnership', value: 'Partnership' },
	{ name: 'Gov', value: 'Gov' },
	{ name: 'Non-profit', value: 'Non-profit' }
];

const ACTIVATE_MERCHANT = gql`
	mutation ActivateMerchant($merchantProps: MerchantProps) {
		activateMerchant(merchantProps: $merchantProps) {
			id
			status
		}
	}
`;
