export default function setColor(status) {
	switch (status) {
		case 'active':
		case 'approved':
		case 'paid':
			return 'success';

		case 'paused':
		case 'pending':
		case 'refunded':
			return 'warning';

		case 'canceled':
		case 'declined':
		case 'past due':
		case 'refund':
		case 'refund error':
			return 'danger';

		default:
			break;
	}
}
