import './App.sass';
import React, { useEffect } from 'react';

import analytics from 'react-segment';

import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAppAuth } from 'services/firebase';

import Auth from 'pages/AuthPage';
import Account from './Account';

import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

// const API_URL = process.env.REACT_APP_API_ROOT_URL;
const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY;

analytics.default.load(SEGMENT_KEY);

export default function App() {
	const [ user, loading, error ] = useAuthState(firebaseAppAuth);

	useEffect(
		() => {
			if (user)
				window.analytics.identify(user.uid, {
					name: user.displayName,
					email: user.email,
					createdAt: user.metadata.creationTime,
					platform: 'unmerchant'
				});
		},
		[ user ]
	);

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	const client = new ApolloClient({
		cache: new InMemoryCache(),
		link: new HttpLink({
			headers: {
				Authorization: user ? user.uid : null
			},
			uri: '/.netlify/functions/graphql'
		})
	});

	return <ApolloProvider client={client}>{user ? <Account /> : <Auth />}</ApolloProvider>;
}
