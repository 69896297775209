import React from 'react';
import { navigate } from '@reach/router';

export default function Toolbar({ itemID, buttons, size, color }) {
	return (
		<nav className="level is-mobile">
			<div className="level-left">
				{buttons.map(({ action, icon, text, color, link, loading, disabled }) => {
					const onClick = link ? () => navigate(`/${link}/${itemID}`) : () => action(itemID);
					switch (size) {
						case 'large':
							return (
								<button
									className={`level-item button is-medium is-${color} ${!loading || 'is-loading'}`}
									onClick={onClick}
									key={icon}
									aria-label="reply"
									disabled={disabled}
								>
									<span className="is-capitalized has-text-weight-semibold">{text}</span>
									<span className="icon">
										<i className={`fas fa-${icon}`} aria-hidden="true" />
									</span>
								</button>
							);

						default:
							return (
								<button
									className={`level-item button is-${size} is-white`}
									onClick={onClick}
									key={icon}
									aria-label="reply"
									disabled={disabled}
								>
									<span className="icon">
										<i className={`fas fa-${icon} fa-lg has-text-${color}`} aria-hidden="true" />
									</span>
								</button>
							);
					}
				})}
			</div>
		</nav>
	);
}
