export default function brandIcon(brand) {
	switch (brand) {
		case 'visa':
			return 'cc-visa';

		case 'mastercard':
			return 'cc-mastercard';

		case 'amex':
			return 'cc-amex';

		case 'discover':
			return 'cc-discover';

		default:
			return 'credit-card';
	}
}
