import React from 'react';

import setColor from 'utils/setColor';

export default function({ type, item, size }) {
	switch (type) {
		case 'scheduled':
			return (
				<div>
					<div className="tags has-addons">
						<span className={`tag is-${size} has-text-weight-bold is-${setColor(item.status)}`}>
							{item.status}
						</span>
						{item.authCode && <span className={`tag is-${size} is-dark`}>{item.authCode}</span>}
					</div>
				</div>
			);

		case 'transaction':
			return (
				<div className="tags has-addons">
					<span className={`tag is-${size} has-text-weight-bold is-${setColor(item.status)}`}>
						{item.status}
					</span>
					{item.isLive ? (
						item.status === 'approved' && <span className={`tag is-${size} is-dark`}>{item.authCode}</span>
					) : (
						<span className={`tag is-warning has-text-weight-bold is-${size}`}>test</span>
					)}
				</div>
			);

		default:
			return null;
	}
}
