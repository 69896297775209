import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Router } from '@reach/router';

import { CloudinaryContext } from 'cloudinary-react';
import { ModalProvider } from 'react-modal-hook';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAppAuth } from 'services/firebase';

import Layout from 'components/ui/Layout';
import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

// import CampaignSetup from 'components/CampaignWizard';

// **** Pages ****
import Dashboard from 'pages/Dashboard';
import Customers from 'pages/Customers';
import Activity from 'pages/Activity';
import Invoices from 'pages/Invoices';
import Scheduled from 'pages/Scheduled';

// **** Profiles ****
import CustomerProfile from 'components/profiles/Customer';
import InvoiceProfile from 'components/profiles/Invoice';
import ScheduledProfile from 'components/profiles/Scheduled';
import TransactionProfile from 'components/profiles/Activity';

// **** Forms ****
import AccountSetup from 'components/forms/AccountSetup';
import ActivateAccount from 'components/forms/Activate';

import UserContext from 'context/UserContext';

// TODO ******** TEST UID ****** CLEAR STRING TO CANCEL *******

// const TEST_UID = '5c0056ca9158ca0015a2ea41'; // Matt
// const TEST_UID = '5bd989afc6fffa00153b0920'; // Dan
// const TEST_UID = '5d13a218d311540017956663'; // Auto Glass
const TEST_UID = '';

// TODO *******************************************************

export default function Account() {
	const [ user ] = useAuthState(firebaseAppAuth);

	const { loading, data, error } = useQuery(CURRENT_USER, {
		variables: { email: user.email }
	});

	useEffect(
		() => {
			if (user && data && data.accounts[0])
				window.analytics.identify(user.uid, {
					name: user.displayName,
					email: user.email,
					company: {
						id: data.accounts[0].id,
						name: data.accounts[0].name
					},
					createdAt: user.metadata.creationTime
				});
		},
		[ user, data ]
	);

	if (loading) return <Spinner />;
	if (error || data.accounts.length < 1) return <Error error={error} />;

	if (data && data.accounts && data.accounts.length > 0) {
		const [ account ] = data.accounts;

		return (
			<UserContext.Provider value={{ user, account }}>
				<CloudinaryContext cloudName="finhub">
					<ModalProvider>
						{account.status === 'new' ? (
							<AccountSetup />
						) : (
							<Router>
								<Layout path="/">
									<Dashboard path="/" />

									<Activity path="activity" />
									<TransactionProfile path="activity/:itemID" />

									<Customers path="customers" />
									<CustomerProfile path="customers/:itemID" />

									<Invoices path="invoices" />
									<InvoiceProfile path="invoices/:itemID" />

									<Scheduled path="scheduled" />
									<ScheduledProfile path="scheduled/:itemID" />

									<ActivateAccount path="/activate" />
								</Layout>
								<Layout default />
							</Router>
						)}
					</ModalProvider>
				</CloudinaryContext>
			</UserContext.Provider>
		);
	}

	return null;
}

// export default gql`
//   mutation ActivateMerchant($merchantProps: MerchantProps) {
//     activateMerchant(merchantProps: $merchantProps) {
//       id
//       status
//     }
//   }
// `;

// export default gql`
// 	mutation OnboardMerchant($merchantProps: MerchantProps) {
// 		onboardMerchant(merchantProps: $merchantProps) {
// 			id
// 		}
// 	}
// `;

// export default gql`
//   mutation ActivateMerchant($merchantProps: MerchantProps) {
//     activateMerchant(merchantProps: $merchantProps) {
//       id
//       isLive
//     }
//   }
// `;

// export default gql`
//   mutation UpdateMerchant($merchantProps: MerchantProps) {
//     updateMerchant(merchantProps: $merchantProps) {
//       id
//     }
//   }
// `;

const CURRENT_USER = gql`
	query CurrentUser($email: String) {
		accounts(email: $email) {
			id
			name
			key
			apiKey
			status
			isLive
		}
	}
`;
