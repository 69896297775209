import React from 'react';

export function Box({ children, color }) {
	return <div className={`box has-background-${color}`}>{children}</div>;
}

export function Button({ type, action, children, icon, color, size, block, disabled }) {
	return (
		<div className="control">
			<button
				type={type}
				onClick={action}
				disabled={disabled}
				className={`button is-${color || 'primary'} is-${size || 'normal'} ${!block || 'is-fullwidth'}`}
			>
				{icon && (
					<span className="icon">
						<i className={`fas fa-${icon} is-${size}`} />
					</span>
				)}
				<span>
					<strong>{children}</strong>
				</span>
			</button>
		</div>
	);
}

export function ButtonGroup({ children, attached, centered, right }) {
	return (
		<div className={`buttons ${!attached || 'has-addons'} ${!centered || 'is-centered'} ${!right || 'is-right'}`}>
			{children}
		</div>
	);
}

export function Content({ children }) {
	return <div className="content">{children}</div>;
}

export function Delete() {
	return <button className="delete" />;
}

export function Icon({ brand, name, size = '', color = 'grey', container }) {
	return (
		<span className={`icon is-${container}`}>
			<i className={`${!brand ? 'fas' : 'fab'} fa-${name} fa-${size} has-text-${color}`} />
		</span>
	);
}

export function Notification({ children, color = 'light' }) {
	return <div className={`notification is-${color}`}>{children}</div>;
}

export function Progress({ value, color, size }) {
	return <progress value={value} max="100" className={`progress is-${color} is-${size}`} />;
}

export function Tabs({ children, size }) {
	return (
		<div className="tabs">
			<ul>{children}</ul>
		</div>
	);
}

export function Tab({ name, icon, active, setActive }) {
	return (
		<li className={active ? 'is-active' : ''} onClick={() => setActive(name)}>
			<a>
				{icon && (
					<span className="icon is-medium">
						<i className={`fas fa-${icon}`} aria-hidden="true" />
					</span>
				)}
				<span className="is-capitalized">{name}</span>
			</a>
		</li>
	);
}
