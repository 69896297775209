import React from 'react';
import { Link } from '@reach/router';

import UserAvatar from 'react-user-avatar';

import Toolbar from 'components/ui/Toolbar';
import StatusTag from 'components/ui/StatusTag';
import { Box, Icon } from 'components/ui/bulma/elements';
import { Level, Left, Right, Item } from 'components/ui/bulma/layout';

import formatDate from 'utils/formatDate';
import setColor from 'utils/setColor';
import statusIcon from 'utils/statusIcon';
import customerName from 'utils/customerName';

export default function Scheduled({ data, view, actions }) {
	switch (view) {
		case 'large':
			return <Large data={data} actions={actions} />;

		case 'grid':
			return <Grid data={data} actions={actions} />;

		case 'table':
			return <Table data={data} actions={actions} />;

		default:
			return null;
	}
}

const Large = ({
	data: item,
	data: {
		id,
		startDate,
		nextRun,
		lastRun,
		amount,
		description,
		frequency,
		status,
		consumer,
		consumer: { id: consumerID, email }
	},
	actions: [ setItem ]
}) => {
	const buttons = [ { link: 'scheduled', icon: 'search-plus' } ];
	return (
		<Box color="white">
			<article className={`message is-${setColor(status)}`}>
				<div className="message-body">
					<Level>
						<Left>
							<Item>
								<StatusTag type="scheduled" item={item} size="medium" />
							</Item>
						</Left>
						<Right>
							<Item>
								<strong>{formatDate(nextRun)}</strong>
							</Item>
						</Right>
					</Level>
					<Level>
						<Left>
							<Item>
								<p className="title is-2">
									<small>$</small>
									{amount} <small>/ {frequency}</small>
								</p>
							</Item>
						</Left>
						<Right>
							<Item>
								<figure className="image is-64x64">
									<UserAvatar
										size="64"
										name={customerName(consumer)}
										src={consumer.avatar}
										colors={[ '#ccc', '#aaa', '#ddd' ]}
									/>
								</figure>
							</Item>
							<Item>
								<Link to={`/customers/${consumerID}`}>
									<p className="title is-5">{customerName(consumer)}</p>
								</Link>
							</Item>
						</Right>
					</Level>
				</div>
			</article>
			<Toolbar itemID={id} buttons={buttons} color="primary" />
		</Box>
	);
};

const Grid = ({
	data: item,
	data: {
		id,
		startDate,
		nextRun,
		lastRun,
		amount,
		description,
		frequency,
		status,
		consumer,
		consumer: { id: consumerID, email }
	},
	actions: [ setItem ]
}) => {
	const buttons = [ { action: setItem, icon: 'search-plus' } ];

	return (
		<div className="column is-narrow">
			<Box color="white">
				<article className={`message is-${setColor(status)}`}>
					<div className="message-body">
						<article className="media">
							<div className="media-left">
								<p className="title is-1">
									<small>$</small>
									{Number(amount).toFixed()}
								</p>

								<p className="subtitle is-5">{frequency}</p>
							</div>
							<div className="media-content">
								<div className="content">
									<p className="title is-5">{customerName(consumer)}</p>
								</div>
								<Toolbar itemID={id} buttons={buttons} color="primary" size="small" />
							</div>
						</article>
					</div>
				</article>
			</Box>
		</div>
	);
};

const Table = ({
	data: {
		id,
		startDate,
		nextRun,
		lastRun,
		amount,
		description,
		frequency,
		status,
		consumer,
		consumer: { id: consumerID, email }
	},
	actions: [ setItem ]
}) => {
	const buttons = [ { action: setItem, icon: 'search-plus' } ];
	return (
		<tr>
			<td>
				<Icon name={statusIcon(status)} color={setColor(status)} />
			</td>
			<td>{customerName(consumer)}</td>
			<td>
				<strong>${Number(amount).toFixed(2)}</strong>
			</td>
			<td>{frequency}</td>
			<td>{formatDate(lastRun, 'date')}</td>
			<td>{formatDate(nextRun, 'date')}</td>
			<td>
				<Toolbar itemID={id} buttons={buttons} size="small" color="primary" />
			</td>
		</tr>
	);
};
