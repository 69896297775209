import React from 'react';

export default function ButtonSelector({ label, size = '', buttons, active, setActive, error, help }) {
	return (
		<div className="field is-centered">
			<label className="label is-large">{label}</label>
			<div className="control">
				<div className="buttons has-addons">
					{buttons.map(({ name, value }) => (
						<span
							key={value}
							className={`button is-${size} ${active === value ? 'is-primary' : ''} ${error
								? 'is-danger is-outlined'
								: ''}`}
							onClick={() => setActive(value)}
						>
							{name}
						</span>
					))}
				</div>
			</div>
			{error && <p className="help is-danger">{error}</p>}
		</div>
	);
}
