import React from "react";
import UserAvatar from "react-user-avatar";

import Toolbar from "components/ui/Toolbar";

import { Icon } from "components/ui/bulma/elements";
export default function Hero({
  color = "light",
  icon,
  avatar,
  title,
  subtitle,
  tabs,
  activeTab,
  setTab,
  view,
  setView,
  toolbar,
  item
}) {
  return (
    <section className={`hero is-${color} is-bold box`}>
      {toolbar && (
        <div className="hero-head">
          <nav className="level">
            <div className="level-left" />

            <div className="level-right">
              <Toolbar
                itemID={item}
                buttons={toolbar}
                color="primary"
                size="large"
              />
            </div>
          </nav>
        </div>
      )}
      <div className="hero-body">
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              {icon && (
                <Icon name={icon} container="large" size="3x" color="white" />
              )}
            </div>
            <div className="level-item">
              {avatar && <UserAvatar size="100" name={avatar} src={avatar} />}
            </div>
            <div className="level-item">
              <div>
                <p className="title">{title}</p>
                <p className="subtitle">{subtitle}</p>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div className="hero-foot is-hidden-mobile">
        <nav className="level">
          <div className="level-left">
            {tabs && (
              <Tabs
                tabs={tabs}
                color={color}
                active={activeTab}
                setTab={setTab}
              />
            )}
          </div>

          <div className="level-right">
            <ToggleViewBar view={view} color={color} setView={setView} />
          </div>
        </nav>
      </div>
    </section>
  );
}

const Tabs = ({ color, tabs, active, setTab }) => (
  <div className="field has-addons">
    {tabs.map(tab => (
      <p className="control" key={tab.title}>
        <button
          className={`button is-${color} ${
            active === tab.title ? "is-active" : ""
          }`}
          onClick={() => setTab(tab.title)}
        >
          <span className={`icon is-medium`}>
            <i className={`fas fa-${tab.icon}`} />
          </span>
          <span className={`has-text-weight-semibold is-capitalized`}>
            {tab.title}
          </span>
        </button>
      </p>
    ))}
  </div>
);

// ${active === tab.title ? 'has-text-dark' : ''}

const ToggleViewBar = ({ view, color, setView }) => (
  <div className="field has-addons">
    <p className="control">
      <button className={`button is-${color}`} onClick={() => setView("large")}>
        <span
          className={`icon is-small ${view === "large" && "has-text-dark"}`}
        >
          <i className="fas fa-address-card fa-lg" />
        </span>
      </button>
    </p>
    <p className="control">
      <button className={`button is-${color}`} onClick={() => setView("grid")}>
        <span className={`icon is-small ${view === "grid" && "has-text-dark"}`}>
          <i className="fas fa-th-large fa-lg" />
        </span>
      </button>
    </p>
    <p className="control">
      <button className={`button is-${color}`} onClick={() => setView("table")}>
        <span
          className={`icon is-small ${view === "table" && "has-text-dark"}`}
        >
          <i className="fas fa-th fa-lg" />
        </span>
      </button>
    </p>
  </div>
);
