import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';

import numeral from 'numeral';

import Toolbar from 'components/ui/Toolbar';
import { Tiles, Vertical } from 'components/ui/bulma/layout';

import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

import customerName from 'utils/customerName';
import formatDate from 'utils/formatDate';

import UserContext from 'context/UserContext';

export default function Dashboard() {
	const { account: { id } } = useContext(UserContext);
	const { data, loading, error } = useQuery(ACCOUNT, {
		variables: { id }
	});

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	const { account: { totalVolume, avgTransaction, paymentCount, payments } } = data;

	return (
		<Tiles>
			<Vertical size="12">
				<div className="tile">
					<div className="tile is-parent is-vertical">
						<article className="tile is-child notification is-primary">
							<p className="subtitle is-3">Total Sales</p>
							<p className="title is-1">
								<small>$</small>
								{totalVolume ? numeral(totalVolume).format('0,0.00') : 0}
							</p>
							{/* <p className="subtitle">Your total sales volume</p> */}
						</article>
						<article className="tile is-child notification is-dark">
							<p className="subtitle is-3">Transactions</p>
							<p className="title is-1">{paymentCount ? numeral(paymentCount).format('0,0') : 0}</p>

							{/* <p className="subtitle">Payments, invoices and more</p> */}
						</article>
						<article className="tile is-child notification is-light">
							<p className="subtitle is-3">Average</p>
							<p className="title is-1">
								<small>$</small>
								{avgTransaction ? numeral(avgTransaction).format('0,0.00') : 0}
							</p>

							{/* <p className="subtitle">Average sales ticket across all transactions</p> */}
						</article>
					</div>

					{/**********  Activity  **********/}
					<div className="tile is-parent">
						<article className="tile is-child notification is-light">
							<p className="title">Activity</p>
							<Activity data={payments} />
						</article>
					</div>

					{/**********  Offers  **********/}
					<div className="tile is-parent is-vertical is-3">
						<article className="tile is-child notification">
							<p className="title">Mobile app</p>
							<p className="subtitle">
								Download our free mobile app and run your business from your smartphone.
							</p>

							<a
								className="button is-medium is-fullwidth is-black"
								href="https://play.google.com/store/apps/details?id=ninja.payment.merchant"
								target="_blanc"
							>
								<span className="icon is-large">
									<i className="fab fa-google-play" />
								</span>

								<span>Google Play</span>
							</a>

							<br />

							<a className="button is-medium is-fullwidth is-black">
								<span className="icon is-large">
									<i className="fab fa-apple fa-lg" />
								</span>
								<span>Coming soon</span>
							</a>
						</article>

						{/**********  Messages  **********/}
						<article className="tile is-child notification">
							<p className="title">Messages</p>
							{/* <p className="subtitle">Payments, invoices and more</p> */}
							No messages yet.
						</article>
					</div>
				</div>
				{/* <div className="tile is-parent">
						<article className="tile is-child notification is-light">
							<p className="title">Waiting for Review</p>
							<p className="subtitle">Please review these posts</p>
							<PendingPosts />
						</article>
					</div> */}
			</Vertical>
		</Tiles>
	);
}

const Activity = ({ data: payments }) => {
	const buttons = [ { link: 'activity', icon: 'search-plus', color: 'primary' } ];
	return (
		<div>
			{payments.map(({ id, status, amount, date, consumer }) => (
				<div key={id}>
					<div className="box">
						<article className="media">
							<figure className="media-left">
								<span className="title is-3">
									<small>$</small>
									{amount}
								</span>
								<br />
								{status === 'declined' && <span className="tag is-danger">declined</span>}
							</figure>
							<div className="media-content">
								<div className="content">
									<strong>{customerName(consumer)}</strong>
									<br />
									<small>{formatDate(date)}</small>
									<Toolbar itemID={id} buttons={buttons} color="primary" size="small" />
								</div>
							</div>
						</article>
					</div>
					<small />
				</div>
			))}
		</div>
	);
};

// *****************************************
// **************** GraphQL ****************
// *****************************************

const ACCOUNT = gql`
	query Account($id: ID!) {
		account(id: $id) {
			id
			paymentCount
			totalVolume
			avgTransaction
			approvedCount
			declinedCount
			payments {
				id
				date
				amount
				status
				isLive
				consumer {
					id
					firstName
					lastName
					email
				}
			}
		}
	}
`;
