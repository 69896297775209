import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Formik, Form, Field } from 'formik';
import { Image, Transformation } from 'cloudinary-react';

import { firebaseAppAuth, providers } from 'services/firebase';

import { Button, Notification } from 'components/ui/bulma/elements';
import { Input } from 'components/ui/bulma/form';

export default function AuthPage() {
	const [ isLogin, toggleLogin ] = useState(false);
	const [ isReset, toggleReset ] = useState(false);

	const { loading, data, error } = useQuery(PLATFORM);

	if (loading) return null;
	if (error) return <div>{error}</div>;

	return (
		<section className="hero is-fullheight">
			<div className="hero-body">
				<div className="container">
					<div className="columns is-mobile is-vcentered">
						<div className="column has-text-left">
							<div style={{ height: 100 }}>
								<img
									src={`https://res.cloudinary.com/finhub/image/upload/c_scale,h_100/v1587574011/unmerchant/platforms/${data
										.platform.logo}`}
								/>

								{/* <Image cloudName="finhub" publicId={`unmerchant/platforms/${data.platform.logo}`} /> */}
							</div>
						</div>
						<div className="column has-text-right">
							{isReset ? (
								<div>
									<h1 className="title">Reset Password</h1>
									<h2 className="subtitle">Get a secure link and set a new password</h2>
								</div>
							) : !isLogin ? (
								<div>
									<h1 className="title">Create Free Account</h1>
									<h2 className="subtitle">Free, no-obligation, secure account</h2>
								</div>
							) : (
								<div>
									<h1 className="title">Sign In</h1>
									<h2 className="subtitle">Sign in into existing account</h2>
								</div>
							)}
						</div>
					</div>

					<div className="columns box">
						<div className="column is-7">
							{isReset ? <Reset /> : isLogin ? <Login /> : <SignUp />}
							<br />
							<div className="field is-grouped">
								{isReset || (
									<Button color="text" action={() => toggleLogin(!isLogin)}>
										<strong>{isLogin ? 'No account? Sign up' : 'Have an account? Sign in'}</strong>
									</Button>
								)}
								<Button color="text" action={() => toggleReset(!isReset)}>
									<strong>{isReset ? 'Back' : 'Reset Password'}</strong>
								</Button>
							</div>
						</div>
						<div className="is-divider-vertical" data-content="OR" />
						<div className="column">
							<button
								className="button is-large is-light"
								onClick={() => firebaseAppAuth.signInWithPopup(providers.googleProvider)}
							>
								<span className="icon">
									<i className="fab fa-google" />
								</span>
								<span>Login with Google</span>
							</button>
							<br />
							<br />
							<button
								className="button is-large is-black"
								onClick={() => firebaseAppAuth.signInWithPopup(providers.githubProvider)}
							>
								<span className="icon">
									<i className="fab fa-github" />
								</span>
								<span>Login with GitHub</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="hero-foot">
				<nav className="tabs">
					<div className="container">
						<p>© 2020 Unmerchant</p>
					</div>
				</nav>
			</div>
		</section>
	);
}

const validate = (values) => {
	let errors = {};

	if (!values.email) {
		errors.email = 'Please enter your email!';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email))
		errors.email = 'This email address is invalid';

	if (!values.password) {
		errors.password = 'Password is required!';
	} else if (values.password.length < 8) {
		errors.password = 'Password should be longer!';
	}

	return errors;
};

const handleAuth = async (values, { setSubmitting, setStatus, setErrors }) => {
	setSubmitting(true);

	try {
		await firebaseAppAuth.createUserWithEmailAndPassword(values.email, values.password);
	} catch (error) {
		if (error.code === 'auth/email-already-in-use') {
			try {
				await firebaseAppAuth.signInWithEmailAndPassword(values.email, values.password);
			} catch (error) {
				setStatus(error.message);
			}
		} else {
			setStatus(error.message);
		}
	}

	setSubmitting(false);
};

const SignUp = () => (
	<Formik
		initialValues={{
			email: '',
			password: ''
		}}
		validate={validate}
		onSubmit={handleAuth}
	>
		{({ status, isSubmitting, handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
			<Form>
				<Field
					name="email"
					label="Business Email"
					size="large"
					icon="at"
					component={Input}
					placeholder="Email"
					autoComplete="username"
				/>
				<br />
				<Field
					name="password"
					type="password"
					label="Set your password"
					size="large"
					icon="lock"
					component={Input}
					placeholder="Password"
					autoComplete="current-password"
				/>
				<br />
				{status && <Notification>{status}</Notification>}
				<Button full type="submit" color="primary" size="large" icon="check-circle">
					Create Account
				</Button>
			</Form>
		)}
	</Formik>
);
const Login = () => (
	<Formik
		initialValues={{
			email: '',
			password: ''
		}}
		validate={validate}
		onSubmit={handleAuth}
	>
		{({ status, isSubmitting, handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
			<Form>
				<Field
					label="Email"
					name="email"
					icon="at"
					size="large"
					component={Input}
					placeholder="Email"
					autoComplete="username"
				/>
				<br />
				<Field
					label="Password"
					name="password"
					type="password"
					icon="lock"
					size="large"
					component={Input}
					placeholder="Password"
					autoComplete="current-password"
				/>
				<br />
				{status && <Notification color="danger">{status}</Notification>}
				<Button full type="submit" color="primary" size="large" icon="key">
					Sign In
				</Button>
			</Form>
		)}
	</Formik>
);

const Reset = () => (
	<Formik
		initialValues={{
			email: ''
		}}
		validate={(values) => {
			let errors = {};

			if (!values.email) {
				errors.email = 'Please enter your email!';
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email))
				errors.email = 'This email address is invalid';

			return errors;
		}}
		onSubmit={(values, { setSubmitting, setStatus }) => {
			try {
				firebaseAppAuth
					.sendPasswordResetEmail(values.email)
					.then(() => setStatus('Please check your email for the next steps.'));
			} catch (error) {
				setStatus(error.message);
			}

			setSubmitting(false);
		}}
	>
		{({ status, isSubmitting, handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
			<Form>
				<Field
					name="email"
					label="Account Email"
					icon="at"
					size="large"
					component={Input}
					placeholder="Email"
					autoComplete="username"
				/>
				<br />
				{status && <Notification>{status}</Notification>}
				<Button full type="submit" color="primary" size="large" icon="envelope" disabled={isSubmitting}>
					Reset Password
				</Button>
			</Form>
		)}
	</Formik>
);

const PLATFORM = gql`
	query Platform {
		platform {
			id
			name
			logo
		}
	}
`;
