export default function statusIcon(status) {
	switch (status) {
		case 'active':
		case 'approved':
		case 'paid':
			return 'check-circle';

		case 'past due':
			return 'calendar-times';

		case 'paused':
			return 'pause-circle';

		case 'pending':
			return 'clock';

		case 'canceled':
		case 'declined':
			return 'times-circle';

		case 'refunded':
			return 'undo';

		case 'refund error':
			return 'exclamation-circle';

		default:
			return null;
	}
}
