import React, { useState } from 'react';

import Menu from 'components/ui/Menu';
import Navbar from 'components/ui/Navbar';
// import { Footer } from 'components/ui/bulma/layout';

export default function Layout({ children }) {
	const [ extendedMenu, extendMenu ] = useState(false);
	return (
		<div>
			<Navbar extendedMenu={extendedMenu} extendMenu={extendMenu} />
			<div className="columns">
				<div className="column is-narrow">
					<Menu extendedMenu={extendedMenu} extendMenu={extendMenu} />
				</div>
				<div className="column">
					<div className="is-hidden-mobile">
						<br />
						<br />
					</div>
					<section className="section">{children}</section>
				</div>
			</div>
		</div>
	);
}
